<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="500px"
        >
          
          <v-card
            class="mx-auto profiled"
          >
            <v-img
              src="/assets/images/passengerbg.png"
              height="100px"
              dark
            >

            <v-btn
              color="black"
              icon
              style="position:absolute;top:.5rem;right:0"
              @click="show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            </v-img>
            <div class="content-wrapper">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  height="3"
                  v-if="profiledetails == null"
                ></v-progress-linear>
              <v-simple-table dense v-else>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Category</td>
                      <td colspan="3">{{ profiledetails.request_category }}</td>
                    </tr>
                    <tr>
                      <td>Booking made at</td>
                      <td>{{ moment(profiledetails.created_at).format("YYYY-MM-DD H:m:s") }}</td>
                      <td>Trip Type</td>
                      <td>
                        <v-chip dark v-if="profiledetails.trip_type === '1'">One Way</v-chip>
                        <v-chip dark v-else>Wait and Return</v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Ref No</td>
                      <td>{{ profiledetails.ref_no }}</td>
                      <td>Status</td>
                      <td>
                        <v-chip dark color="primary" v-if="profiledetails.status === 'Waiting'">{{ profiledetails.status }}</v-chip>
                        <v-chip dark color="#82AF6F" v-if="profiledetails.status === 'Active'">{{ profiledetails.status }}</v-chip>
                        <v-chip dark color="#ABBAC3" v-if="profiledetails.status === 'Cancelled'">{{ profiledetails.status }}</v-chip>
                        <v-chip dark v-if="profiledetails.status !== 'Waiting' && profiledetails.status !== 'Active' && profiledetails.status !== 'Cancelled'">{{ profiledetails.status }}</v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>
                        <div v-if="profiledetails.bookingpassengers.length === 0">{{ profiledetails.passenger_name }}</div>
                        <div v-else style="display:flex;flex-direction:column !important">
                          <span v-for="(passenger,index) in profiledetails.bookingpassengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.name }}</span>
                        </div>
                      </td>
                      <td>Telephone</td>
                      <td>
                        <div v-if="profiledetails.bookingpassengers.length === 0">{{ profiledetails.telephone }}</div>
                        <div v-else style="display:flex;flex-direction:column !important">
                          <span v-for="(passenger,index) in profiledetails.bookingpassengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.telephone }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Organisation</td>
                      <td colspan="3">
                        <span v-if="profiledetails.organisation_name !== null">{{ profiledetails.organisation_name }}</span>
                        <span v-else>Private</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Pickup Date</td>
                      <td colspan="3">{{ profiledetails.pickup_date }} {{ profiledetails.pickup_time }}</td>
                    </tr>
                    <tr v-if="profiledetails.bookingprojects.length > 0">
                      <td>Projects</td>
                      <td colspan="3">
                        <div style="display:flex;flex-direction:column !important">
                          <span style="display:flex;" v-for="(project, index) in profiledetails.bookingprojects" :key="index">
                            {{ project.unit }} - {{ project.name }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="profiledetails.bookingsubprojects.length > 0">
                      <td>Projects</td>
                      <td colspan="3">
                        <v-sheet
                          elevation="0"
                          class="py-0 px-0"
                        >
                          <v-chip-group
                            mandatory
                            active-class="primary--text"
                          >
                            <v-chip
                              v-for="(project, index) in profiledetails.bookingsubprojects"
                              :key="index"
                            >
                               <span style="display:flex;">
                                 {{ project.unit }} {{ project.name }}
                                </span>
                            </v-chip>
                          </v-chip-group>
                        </v-sheet>
                      </td>
                    </tr>
                    <tr>
                      <td>From</td>
                      <td colspan="3">{{ profiledetails.travelfrom }}</td>
                    </tr>
                    <tr>
                      <td>Stop</td>
                      <td colspan="3">
                        <div style="display:flex;flex-direction:column !important">
                          <span v-if="profiledetails.travelstop1 !== null" style="display:flex;">
                            <v-icon class="mr-2">mdi-circle-small</v-icon> {{ profiledetails.travelstop1 }}
                          </span>
                          <span v-if="profiledetails.travelstop2 !== null" style="display:flex;align-items:center">
                            <v-icon>mdi.dots-vertical</v-icon>
                          </span>
                          <span v-if="profiledetails.travelstop2 !== null" style="display:flex;align-items:center">
                            <v-icon class="mr-2">mdi-circle-small</v-icon> {{ profiledetails.travelstop2 }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>To</td>
                      <td colspan="3">{{ profiledetails.travelto }}</td>
                    </tr>
                    <tr>
                      <td>Assigned Vehicle</td>
                      <td colspan="3">
                        <div v-if="profiledetails.bookingvehicles.length > 0"><span v-for="(res,i) in profiledetails.bookingvehicles" :key="i">{{ res.name }} - {{ res.registration }},</span></div>
                        <div v-else>N/A</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      <td colspan="3">
                        <div v-if="profiledetails.bookingdrivers.length > 0"><span v-for="(res,i) in profiledetails.bookingdrivers" :key="i">{{ res.name }} - {{ res.telephone }},</span></div><div v-else> N/A </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Flight</td>
                      <td colspan="3">
                        {{ profiledetails.flight }}
                      </td>
                    </tr>
                    <tr>
                      <td>Remarks</td>
                      <td colspan="3">
                        <span
                          v-if="profiledetails.remarks !== null"
                        >
                          {{ profiledetails.remarks }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Booked By</td>
                      <td colspan="3">
                        <span
                          v-if="profiledetails.user !== null"
                        >
                          {{ profiledetails.user_name }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="profiledetails.authorization == 'false'">
                      <td>Approvers</td>
                      <td colspan="3">
                        <div style="display:flex;flex-direction:column !important">
                        <!--   <div style="display:flex;flex-direction:column !important" v-for="(project, index) in profiledetails.project" :key="index">
                             
                          </div> -->
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Cancellation</td>
                      <td colspan="3">
                        <span
                          v-if="profiledetails.cancellation_reason !== null"
                        >
                          {{ profiledetails.cancellation_reason }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Driver E-sign</td>
                      <td>
                        <v-img
                            :src="profiledetails.driver_signature"
                            style="width:100%"
                            dark
                            v-if="profiledetails.driver_signature !== null"
                          />
                      </td>
                      <td>Client E-sign</td>
                      <td>
                        <v-img
                            :src="profiledetails.signature"
                            style="width:100%"
                            dark
                            v-if="profiledetails.signature !== null"
                          />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

          </v-card>

      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
      font-weight: 500;
      height: 25px !important;
  }
  .clientprofile td{
    white-space: normal !important; 
  }
  .content-wrapper{
  min-height:3rem !important;
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'ClientProfile',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        labelpickupl: "",
        labeldropl:"",
        directionsService: null,
        directionsDisplay: null,
        coords: {lat: 0, lng: 0},
        destination: {lat: 0, lng: 0},

	}),
	methods: {
		getprofiledetails() {
      // this.$store.state.componentloader = true
      axios.get(`/booking/profile/${this.bookingid}`).then(response=>{
        // this.$store.state.componentloader = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.profiledetails = response.data.profile
        }
        
      })
      .catch(() => {
        // this.$store.state.componentloader = false
        console.log("Unexpected error occured")
      })
    }
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      bookingid() {
        return this.$store.state.bookingid
      },
      profiledetails() {
        return this.$store.state.profiledetails
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
    watch: {
      bookingid: function(newVal, oldVal) {
        if(newVal !== null) {
          this.$store.state.profiledetails = null
          this.getprofiledetails()
        }
      },
  },
	mounted() {

	
	},
	created() {
     
    }
}
</script>