<template>
    <!-- BEGIN: Content-->
    <div class="Reports mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="mb-0 py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex py-2" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>
                  <!-- <div>
                    <v-btn
                      color="#000000"
                      class="ma-1 white--text"
                      depressed
                      :loading="loadingpdf"
                      @click="exportpdf"
                    >
                      Export Pdf
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-pdf-box
                      </v-icon>
                    </v-btn>
                    <v-btn
                      color="#ffffff"
                      class="ma-1 black--text"
                      depressed
                      :loading="loadingexcel"
                      @click="exportexcel"
                    >
                      Export Excel
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-excel-box
                      </v-icon>
                    </v-btn>
                  </div> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-0 py-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                  <v-progress-linear
                    color="primary accent-4"
                    indeterminate
                    height="3"
                    :active="addbookingloader"
                  ></v-progress-linear>
                </div>
              <v-card-title style="display:block;">
                <v-row class="pa-1">
                  <v-col cols="12" ms="12">
                    <v-form class="pt-2"  enctype="multipart/form-data" v-model="valid" ref="form">
                      <v-row>
                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                        >
                        <date-range-picker
                          ref="picker"
                          :showWeekNumbers="showWeekNumbers"
                          :showDropdowns="showDropdowns"
                          :autoApply="autoApply"
                          opens="right"
                          :always-show-calendars="alwaysshowcalendars"
                          v-model="dateRange"
                          @select="updateValues"
                         >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <v-text-field
                                  label="Select date range"
                                  :rules="fieldRules"
                                  v-model="date"
                                  solo
                                ></v-text-field>
                            </template>
                        </date-range-picker>
                      </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                        >
                          <v-autocomplete
                              :items="projects"
                              label="Select project"
                              v-model="item.project_id"
                              clearable
                              @click:clear="item.project_id = ''"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchproject"
                              solo
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                          >
                          <v-autocomplete
                              :items="customers"
                              label="Select staff"
                              v-model="item.customer_id"
                              clearable
                              @click:clear="item.customer_id = ''"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchcustomer"
                              solo
                            ></v-autocomplete>
                        </v-col>

                        <!-- <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                          >
                          <v-autocomplete
                              :items="statuses"
                              label="Select status"
                              v-model="item.status"
                              clearable
                              @click:clear="item.status = ''"
                              item-text="status"
                              item-value="status"
                              :search-input.sync="searchstatus"
                              solo
                            ></v-autocomplete>
                        </v-col>
 -->
                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                        >
                          <v-autocomplete
                            :items="['Client', 'Delivery']"
                            label="Select request category"
                            v-model="item.request_category"
                            :rules="fieldRules"
                            :search-input.sync="searchrequestcategory"
                            solo
                            clearable
                            @click:clear="item.request_category = ''"
                          ></v-autocomplete>
                        </v-col>

                         <v-col
                          cols="12"
                          md="2"
                          xl="2"
                          class="py-0"
                        >
                          <v-btn
                              depressed
                              color="primary darken-1"
                              :loading="loading"
                              :disabled="!valid"
                              @click="fetchreport"
                            >
                              Search
                            </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row style="margin-bottom:0 !important" class="px-3 mt-2">
                  <v-col md="12" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between">


                    <div class="my-0 py-0 mx-4" style="display:flex;align-items:center;justify-content:flex-start">
                      <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:1rem !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                        <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">Sub Total</small>
                        {{

                          (this.bookingreports.reduce( (acc, item) => 
                            {  
                                let amt = 0;
                                if(item.organisationrate.length > 0) {
                                  if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                  }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                  }else {
                                    amt = parseInt(item.organisationrate[0].base_rate)
                                  }
                                }
                              return acc += amt; 
                            }, 0) + this.bookingreports.reduce( (acc, item) => 
                          {  

                            let amt = 0, waitingfee = 0, subamt = 0;
                            if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                              if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                              }
                            }

                            if(item.waiting.length > 0) {
                              subamt = item.waiting.reduce((acc1, value) => { 
                                        let total = 0;
                                        if(value.starttime !== null && value.endtime !== null) {
                                          
                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                          }

                                        }

                                        return  acc1 += total
                                      }, 0)
                            }

                            amt = subamt + waitingfee;
                            
                            return acc += amt; 

                          }, 0)) | currency('')

                        }}
                        
                      </div>
                      <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:0 !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                        <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">VAT</small>
                        {{

                          ((((this.bookingreports.reduce( (acc, item) => 
                            {  
                                let amt = 0;
                                if(item.organisation.vat !== '0') {
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                }
                              return acc += amt; 
                            }, 0) + this.bookingreports.reduce( (acc, item) => 
                          {  

                            let amt = 0, waitingfee = 0, subamt = 0;
                            if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                              if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                              }
                            }

                            if(item.waiting.length > 0) {
                              subamt = item.waiting.reduce((acc1, value) => { 
                                        let total = 0;
                                        if(value.starttime !== null && value.endtime !== null) {
                                          
                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                          }

                                        }

                                        return  acc1 += total
                                      }, 0)
                            }

                            amt = subamt + waitingfee;
                            
                            return acc += amt; 

                          }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                        }}
                        
                      </div>
                      <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:1rem !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                        <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">Total</small>
                        {{

                          (this.bookingreports.reduce( (acc, item) => 
                          {  

                            let amt = 0, waitingfee = 0, subamt = 0;
                            if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                              if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                              }
                            }

                            if(item.waiting.length > 0) {
                              subamt = item.waiting.reduce((acc1, value) => { 
                                        let total = 0;
                                        if(value.starttime !== null && value.endtime !== null) {
                                          
                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                          }

                                        }

                                        return  acc1 += total
                                      }, 0)
                            }

                            amt = subamt + waitingfee;
                            
                            return acc += amt; 

                          }, 0) + this.bookingreports.reduce( (acc, item) => 
                          {  

                            let amt = 0;

                            if(item.expressway.length > 0) {
                              amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                            }
                            
                            return acc += amt; 

                          }, 0) + ((((this.bookingreports.reduce( (acc, item) => 
                            {  
                                let amt = 0;
                                if(item.organisation.vat !== '0') {
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                }
                              return acc += amt; 
                            }, 0) + this.bookingreports.reduce( (acc, item) => 
                          {  

                            let amt = 0, waitingfee = 0, subamt = 0;
                            if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                              if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                              }
                            }

                            if(item.waiting.length > 0) {
                              subamt = item.waiting.reduce((acc1, value) => { 
                                        let total = 0;
                                        if(value.starttime !== null && value.endtime !== null) {
                                          
                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                          }

                                        }

                                        return  acc1 += total
                                      }, 0)
                            }

                            amt = subamt + waitingfee;
                            
                            return acc += amt; 

                          }, 0))*(vat != null ? vat.rate : 0))/100)) + this.bookingreports.reduce( (acc, item) => 
                            {  
                                let amt = 0;
                                if(item.organisationrate.length > 0) {
                                  if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                  }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                  }else {
                                    amt = parseInt(item.organisationrate[0].base_rate)
                                  }
                                }
                              return acc += amt; 
                            }, 0)) | currency('')

                        }}
                        
                      </div>
                    </div>

                    <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                      >
                      <v-text-field
                        v-model="search"
                        label="Search"
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">
                <v-virtual-scroll
                    :items="allbookingreports"
                    :item-height="23"
                    renderless 
                    id="virtualscroll"
                    class="report"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">

                              <div v-if="item.passengers.length === 0">
                                <span v-if="item.passenger_name !== null" v-bind="attrs" v-on="on">
                                  {{ item.passenger_name }}
                                </span>
                              </div>
                              <div v-else style="display:flex;flex-direction:column !important" v-bind="attrs" v-on="on">
                                <span v-for="(passenger,index) in item.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.passenger_name }}</span>
                              </div>
                            </template>
                            <span>
                              <div v-if="item.passengers.length === 0">
                                <span v-if="item.passenger_name !== null">
                                  {{ item.passenger_name }}
                                </span>
                              </div>
                              <div v-else style="display:flex;flex-direction:column !important">
                                <span v-for="(passenger,index) in item.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.passenger_name }}</span>
                              </div>
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <div v-if="item.passengers.length === 0">{{ item.telephone }}</div>
                          <div v-else style="display:flex;flex-direction:column !important">
                            <span v-for="(passenger,index) in item.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.telephone }}</span>
                          </div>
                        </div>
                        <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelfrom }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelfrom }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelto }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelto }}
                            </span>
                          </v-tooltip>
                        </div>
                       <!--  <div class="tcell">
                          <span v-if="item.vehicletype !== null">{{ item.vehicletype.type }}</span>
                          <span v-else>N/A</span>
                        </div> -->
                        <div class="tcell">{{ item.distance | currency('') }}</div>
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                            <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                            <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell"><a :href="`https://evoucher.absolutecabs.com/customer/e-voucher/${item.ref_no}`" target="_blank">View voucher</a></div>
                        <div class="tcell">
                          <span v-if="item.user !== null">
                            {{ item.user.name }}
                          </span>
                          <span v-else>N/A</span>
                        </div>
                    </template>
                  </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>
</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .report .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(5%, 9.091%) !important;*/
       grid-template-columns: 13.091% 9.091% 9.091% 15.091% 15.091% 5.091% 5.091% 5.091% 5.091% 9.091% 9.091%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<style scoped>
  .v-virtual-scroll{
      height: 65vh !important;
    }
    .cardtext{
      height: 65vh !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"ReportsPage",
    components: { DateRangePicker },
    data(){
        return {
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Reports',
              disabled: false,
              href: '',
            },
            {
              text: 'Bookings',
              disabled: false,
              href: '',
            },
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],

          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           searchfilter: "",
           searchrequestcategory: "",
           search: "",
           Drivers: [],
           item: {
            request_category: '',
            project_id: '',
            customer_id: '',
            status: ''
           },
           fields: [
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            // { text: 'Vehicle Type', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
            { text: 'E-voucher', value: 'evoucher' },
            { text: 'Bookedby', value: 'bookedby' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            searchorganisation: null,
            searchproject: null,
            searchcustomer: null,
            searchstatus: null,
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null 
 
        }
    },
    beforeMount() {
      this.$store.state.allbookingreports = []
      // if(!ability.can('view-reports', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    serverPrefetch () {},
    mounted(){

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

      if(this.customers.length == 0 || this.projects.length == 0){
        this.getAddBookings()
      }

    },
    computed: {
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      vat() {
        return this.$store.state.vat
      },
      bookingreports() {
        return this.$store.state.allbookingreports
      },
      allbookingreports() {
        return this.bookingreports.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
        })
      },
      projects() {
        return this.$store.state.projects
      },
      statuses() {
        return this.$store.state.statuses
      },
      customers() {
        return this.$store.state.customers
      },
      organisation_id() {
        return this.$store.state.user.user.organisation.id
      },
      ...mapState({
        searchFilter: 'searchFilter'
      }),
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',this.item.project_id)
          fd.append('customer_id',this.item.customer_id)
          fd.append('status',this.item.status)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/booking/export/org/excel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'booking_report.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',this.item.project_id)
          fd.append('customer_id',this.item.customer_id)
          fd.append('status',this.item.status)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/booking/export/org/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'booking_report.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      fetchreport(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',this.item.project_id)
          fd.append('customer_id',this.item.customer_id)
          fd.append('status',this.item.status)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/booking/reports', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.allbookingreports = response.data.bookings
              this.$store.state.vat = response.data.vat
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },

    }
}
</script>