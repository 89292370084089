<template>
  <div>
      <loader/>
      <div class="dashboard dashboard-container">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-card class="card-shadow mx-2 my-3 stats">
                <v-card-title>
                  <p class="text-h6 text--primary mb-0" style="font-size:.9rem !important;width:100%">
                    Today Bookings
                  </p>
                </v-card-title>
                <v-card-text>
                  <highcharts class="todaych" id="container" :options="pieOptions" ref="piechart"></highcharts>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card class="card-shadow mx-2 my-3 stats">
                <v-card-title>
                  <p class="text-h6 text--primary mb-0" style="font-size:.9rem !important">
                    Booking Statistics {{ currentyear }}
                  </p>
                </v-card-title>
                <v-card-text>
                  <v-simple-table dense >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Time frame
                          </th>
                          <th class="text-left">
                            Bookings
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                        >
                          <td>Today</td>
                          <td>{{ dashboard_stats.today_count | currency('',0) }}</td>
                        </tr>
                        <tr
                        >
                          <td>This Week</td>
                          <td>{{ dashboard_stats.week_count | currency('',0) }}</td>
                        </tr>
                        <tr
                        >
                          <td>This Month</td>
                          <td>{{ dashboard_stats.month_count | currency('',0) }}</td>
                        </tr>
                        <tr
                        >
                          <td>This Year</td>
                          <td>{{ dashboard_stats.year_count | currency('',0) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-card class="card-shadow mx-2 my-3 orgstats">
                <v-card-title>
                  <p class="text-h6 text--primary mb-0" style="font-size:.9rem !important;width:100%">
                    Monthly Organisation Bookings {{ currentyear }}
                  </p>
                </v-card-title>
                <v-card-text>
                  <highcharts class="hc" id="container" :options="stackedColumnOption" ref="chart"></highcharts>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </v-container>
      </div>
  </div>
</template>
<style scoped>
  .dashboard-container table > tbody > tr > td, .dashboard-container table > thead > th, .dashboard-container table > tr{
      font-weight: 500;
      padding-top:.5rem !important;
      padding-bottom:.5rem !important;
  }
  .dashboard-container .v-data-table__wrapper td{
    border-bottom:1px solid #ebeaf2 !important;
  }
  .dashboard-container{
    margin-bottom: 50px;
    margin-top: 0px;
  } 
  @media (min-width: 1280px){

    .dashboard-container{
      max-width:1000px;
    } 

  }
  @media (max-width: 1280px){

    .dashboard-container{
      max-width:100%;
    } 

  }
</style>
<script>
import axios from 'axios'
import loader from '@/components/loader'

export default {
    name: "dashboard",
    components: {loader},
    data: () => ({

      fields: [
        '#',
        { key: 'Name', stickyColumn: true, isRowHeader: true, variant: 'dark' },
        'Email',
        'Telephone',
        'Address',
        'Category',
        ],
        name: '',
        stackedColumnOption: {
          chart: {
              type: 'column',
              height: 380
          },
          title: {
              text: '',
              align: 'left'
          },
          xAxis: {
              categories: []
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Bookings'
              },
              stackLabels: {
                  enabled: true
              }
          },
          legend: {
              enabled: false
            },
          tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
          },
          // plotOptions: {
          //   series: {
          //       stacking: 'normal',
          //       dataLabels: {
          //           enabled: true
          //       }
          //   }
          // },
          plotOptions: {
              column: {
                  stacking: 'normal',
                  dataLabels: {
                      enabled: true
                  }
              }
          },
          series: []
        },
        pieOptions: {
          chart: {
              type: 'pie',
              marginTop: 0,
              height: 240
          },
          title: {
              text: ''
          },
          tooltip: {
          headerFormat: '',
          pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
              'Bookings: <b>{point.y}</b><br/>'
          },
           plotOptions: {
              pie: {
                  size: 140
              }
          },
          series: []
        },
        monthname:"",
        currentyear: ""
    }),
    created() {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      const d = new Date();
      this.monthname = month[d.getMonth()];
      this.currentyear = d.getFullYear();
    },
    mounted(){
      // if(this.$store.state.user == null)
      //   this.name = this.$store.state.user.user.name

      this.$store.state.loader_text = `Welcome, ${ this.$store.state.user.user.name.split(' ')[0] }`

      this.getDashboard()
      this.getCharts();
    },
    computed: {
      months() {
        return this.$store.state.categories
      },
      statisticsChart() {
        return this.$store.state.arrayChart
      },
      dashboard_stats() {
        return this.$store.state.dashboard_stats
      },
    },
    methods: {

      async getCharts(){
        this.$store.state.tableloader = true
        await axios.get('/org-dashboard/charts').then(response=>{
            this.$store.state.tableloader = false

            this.stackedColumnOption.xAxis.categories = response.data.months
            this.stackedColumnOption.series = response.data.series

            this.pieOptions.series = response.data.todayseries

        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async getDashboard(){
        this.$store
          .dispatch('getDashboard')
          .then(() => {
          })
          .catch(() => {
          })
      },
    }
}
</script>