<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'returnBooking'}"
          >
            Return booking
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'addBookingList'}"
          >
            Add booking
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="inprogress">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0" style="padding-top:0 !important;">
              <v-card class="card-shadow pb-0 pt-0">
                <v-card-title class="py-0 my-0" style="display:flex;align-items:center;justify-content:flex-end;height:3rem">
                  <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                      v-model="search"
                      label="Search bookings"
                      class="mx-4"
                      hide-details="auto"
                      clearable
                      @click:clear="onClearClicked"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="2"
                      class="py-0"
                      >
                      <v-autocomplete
                          :items="['Pre Approvals']"
                          label="Select status"
                          v-model="preapproval"
                          clearable
                          @click:clear="preapproval = ''"
                          item-text="status"
                          item-value="status"
                          :search-input.sync="searchapproval"
                          solo
                          hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                  </v-card-title>
                <v-card-text class="px-0 pb-0 cardtext">
                  <div class="empty-state" v-if="allbookings.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                    :items="allbookings"
                    :item-height="23"
                    renderless 
                    id="virtualscroll"
                    class="allbooking"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell" :class="item.color">
                          <div class="name">{{ item.name }}<span v-if="item.bookingfor !== null">({{ item.bookingfor }})</span></div>
                        </div>
                        <div class="tcell" :class="item.color">
                          {{ item.time }}
                        </div>
                        <div class="tcell" :class="item.color">
                          {{ item.date }}
                        </div>
                        <div class="tcell" :class="item.color">
                          <div>{{ item.telephone }}</div>
                        </div>
                        <div class="tcell" :class="item.color">
                          {{ item.request_category }}
                        </div>
                    
                        <div class="tcell" :class="item.color">
                          {{ item.from }}
                        </div>
                        <div class="tcell" :class="item.color">
                          {{ item.to }}
                        </div>
                       
                        <div class="tcell" :class="item.color">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                              <!-- view profile -->
                              <v-list-item
                                  @click="bookingprofile(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Profile</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <!-- repeat booking -->
                              <v-list-item
                                  @click="repeat(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-repeat</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Repeat</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <!-- edit booking -->
                              <v-list-item
                                link
                                :to='{name:"bookingEdit",params:{id:item.id}}'
                                v-if="item.status !== 'Dropped' && item.status !== 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                                >
                                  <v-list-item-icon>
                                    <v-icon color="#000000">mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                              <!-- Cancel booking -->
                              <v-list-item
                                @click="cancelbooking(item.id)"
                                v-if="item.status !== 'Dropped' && item.status !== 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Cancel</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>
                              
                              <!-- undo cancelling -->
                              <v-list-item
                                @click="undocancel(item.id)"
                                v-if="item.status == 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                              >
                                  <v-list-item-icon>
                                    <v-icon color="#000000">mdi-arrow-u-left-top</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                      <v-list-item-title>Reinstate</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                        <div class="tcell" :class="item.color">
                            <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.name }} - {{ res.registration }},</span></div>
                            <div v-else>Pending</div>
                        </div>
                        <div class="tcell" :class="item.color">
                          <span
                          >
                            {{ item.vehicle_type_name }}
                          </span>
                        </div>
                        <div class="tcell" :class="item.color">
                          <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
                          <span
                            v-if="item.authorization == 'false'"
                          >
                            <v-icon>mdi-account-lock</v-icon>
                          </span>
                          <span
                                v-if="item.authorization == 'declined'"
                              >
                                <v-icon>mdi-cancel</v-icon>
                              </span><br>
                          <small style="font-size:.69rem;" v-if="item.authorization == 'declined'">
                                  </small>
                        </div>
                        <div class="tcell" :class="item.color">
                            <span>{{ item.user_name }}</span>
                        </div>
                        <div class="tcell" :class="item.color">
                          <span
                            v-if="item.remarks !== null"
                          >
                            {{ item.remarks }}
                          </span>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <RepeatBooking v-model="repeatdialog" :profiledetails="profiledetails"/>

        <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>

        <CancelBooking v-model="canceldialog" :selected="selected"/>
      </div>
    </div>
</template>
<style scoped>
  @import "@/assets/css/allbookings_scoped";
</style>
<style>
  @import "@/assets/css/allbookings";
</style>
<script>

import CancelBooking from '@/components/bookings/submodules/today/CancelBooking'
import ClientProfile from '@/components/bookings/submodules/today/ClientProfile'
import RepeatBooking from '@/components/bookings/submodules/RepeatBooking'

import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '@/services/ability'
import moment from "moment"
import $ from 'jquery';

export default {
    name:"bookingProgress",
    components: {CancelBooking, ClientProfile, RepeatBooking},
    data(){
        return {
          menu3: false,
          menu4: false,
          tableloader: false,
          search: '',
          searchfilter: '',
          message: false,
          hints: true,
          path: null,
          reveal: false,
          profiledialog: false,
          repeatdialog: false,
          profiledetails: [],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          valid: true,
          labelpickupl: "",
          labeldropl:"",
          messagetext: "",
            currentlat: null,
            currentlong: null,
            directionsService: null,
            directionsDisplay: null,
            searchapproval: null,
            coords: {lat: 0, lng: 0},
            destination: {lat: 0, lng: 0},
            labelstart: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
            labelend: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
          service: null,
          fields: [
            { text: 'Passenger', align: 'start', value: 'name' },
            { text: 'Time', value: 'time', filterable: false },
            { text: 'Date', value: 'date', filterable: false },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Category', value: 'category' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Action', value: 'action' },
            { text: 'Assigned Vehicle', value: 'assigned' },
            { text: 'Car Option', value: 'vehicletype_type' },
            { text: 'Status', value: 'status' },
            { text: 'Booked By', value: 'bookedby' },
            { text: 'Remarks', value: 'remarks' },
           ],
           filters: {
              name: [],
              time: [],
              date: [],
              telephone: [],
              category: [],
              organisation_name: [],
              from: [],
              to: [],
              vehicletype_type: [],
              status: [],
              orderedby: []
            },
            preapproval:'',
            isBusy: false,
            loading: false,
           disabled: false,
           height:"100%",
           rowHeight: 15,
            perPage: 200,
            start: 0,
            timeout: null,
            dates: {},
            date: "",
            daterange: {
              start: '',
              end: ''
            },
            menu:false,
            menu1:false,
            fromdate: null,
            todate: null,
            selected:null,
            reason: '',
            canceldialog:false,
            timer: null
          }
    },
    beforeMount() {
      // if(!ability.can('booking-management', 'all')) {
      //   this.$router.push({ name: 'DashboardPage' })
      //   return
      // }
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    mounted(){

      this.$store.state.loader_text = "Bookings In Progress"

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

        this.getBookingsInProgress()

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      bookings() {
        const startDate = this.fromdate === null || this.fromdate === '' ? this.moment('01/01/2010').format("YYYY-MM-DD") : this.moment(this.fromdate).format("YYYY-MM-DD");// Date or null
        const endDate = this.todate === null || this.todate === '' ? this.moment('01/01/2025').format("YYYY-MM-DD") : this.moment(this.todate).format("YYYY-MM-DD");  // Date or null

        return this.$store.state.bookingsinprogress.filter((d) => {
          return Object.keys(this.filters).every((f) => {
            return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
          });
        })
        .filter(item => {
          this.start = 0
          return item.date.includes(this.date)
        })
        .filter(item => {

          this.start = 0

          const itemDate = item.date
          if(this.preapproval === 'Pre Approvals') {
            return itemDate > moment().format("YYYY-MM-DD") && item.authorization === 'true'
          }else {
            return itemDate.includes('')
          }
          
        })
        .filter(item => {

          this.start = 0

          let name = "",ref_no = "",time = "",date = "",telephone = "",organisation = "",from = "", to = "",vehicletype = "",orderedby = "",status = "";
          if(item.ref_no != null && item.ref_no != 'null') {ref_no = item.ref_no}
          if(item.name != null && item.name != 'null') {name = item.name}
          if(item.time != null && item.time != 'null') {time = item.time}
          if(item.date != null && item.date != 'null') {date = item.date}
          if(item.telephone != null && item.telephone != 'null') {telephone = item.telephone}
          if(item.from != null && item.from != 'null') {from = item.from}
          if(item.to != null && item.to != 'null') {to = item.to}
          if(item.vehicle_type_name != null && item.vehicle_type_name != 'null') {vehicletype = item.vehicle_type_name}
          if(item.orderedby != null && item.orderedby != 'null') {orderedby = item.orderedby}
          if(item.status != null && item.status != 'null') {status = item.status}

            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || ref_no.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || telephone.includes(this.search) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || vehicletype.toLowerCase().includes(this.search.toLowerCase()) || orderedby.toLowerCase().includes(this.search.toLowerCase()) || status.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      allbookings() {
        return this.bookings
      },
    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

    },
    methods:{
      onClearClicked () {
        this.search = ''
      },
      cleardate () {
        this.date = ''
        this.menu = false
      },
      columnValueList(val) {
        return this.bookings.map((d) => d[val]);
      },
      getBookingsInProgress(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getBookingsInProgress')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
    bookingprofile(id) {

      this.$store.state.bookingid = id

      this.profiledialog = true
    },
    repeat(id) {

      this.profiledetails = this.allbookings.filter(booking => booking.id === id )

      this.repeatdialog = true
    },
    undocancel(id){
      axios.get(`/reinstate/booking/${id}`).then(response=>{
        if(response.data.status == parseInt(200)) {
          this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
        }
        
      })
      .catch(() => {
        console.log("Unexpected error occured")
      })
    },
    cancelbooking(id) {
          let index = this.bookings.filter(item => item.id === parseInt(id) )
            if(index.length > 0) {

              this.selected = index[0]
              this.canceldialog = true

            }
        },
        

  }
}
</script>