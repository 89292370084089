<template>
	<div class="generatereport">
    <v-navigation-drawer
      v-model="show"
      absolute
      temporary
      right
      width="500px"
      >
      <v-list-item>
        <v-list-item-content style="height:50px !important;padding:0 !important">
          <v-list-item-title class="d-flex" style="justify-content:space-between;align-items:center;font-weight:500;font-size:13.5px !important"><div style="display:flex">Generated Documents&nbsp;&nbsp;<div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"></v-progress-circular></div></div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <ul class="content-list generaterep" id="itemList">
        <li v-for="(document,index) in documents" :key="index">
          <div class="list-title">
            <span>{{ document.invoice_no }}</span>
            <span style="font-size:10.5px !important;font-weight:400 !important">{{ document.user_name }}</span>
          </div>
          <div class="list-subtitle">
            <div><span style="color:#3c4043 !important;text-decoration:none !important;font-size: 11px !important;">{{ document.start_date }} - {{ document.end_date }}</span> </div>
            <div><span class="mx-1" v-if="document.voucher !== null" @click="download(document,'voucher')" style="color:#F9760B;font-size:10.5px;cursor:pointer">Download Voucher</span>
            <span class="mx-1" v-if="document.excel !== null" @click="download(document,'excel')" style="color:#F9760B;font-size:10.5px;cursor:pointer"> | Download Excel</span>
            <span class="mx-1" v-if="document.pdf !== null" @click="download(document,'pdf')" style="color:#F9760B;font-size:10.5px;cursor:pointer"> | Download Pdf</span></div>
          </div>
        </li>
      </ul>
      <v-pagination
                v-model="currentPage"
                :length="Math.ceil(documents.length/perPage)"
                v-if="documents.length > 0"
              ></v-pagination>

    </v-navigation-drawer>
	</div>
</template>

<style>
.generaterep li{
  cursor: unset !important;
}
.generaterep li:hover{
  background-color: transparent !important;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'

export default {
	name: 'generatereport',
	components: { },
	data: () => ({
    perPage: 15,
    currentPage: 1,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
    valid: true,
    showextra: false,
    sendmeacopy: false,
    isBusy: false,
    loading: false,
    disabled: false,
    details: null,
    search: "",
    item:{
       to: "",
       subject: "",
       body: "",
       cc: "",
       bcc: "",
       attachmentpdf: "",
       base64pdf: "",
       attachmentexcel: "",
       base64excel: "",
       attachmentvoucher: "",
       base64voucher: "",
       clientname: ""
    },
   modes: [],
   email: "",
   filename: "",
   page: null,
   pageCount: 1,
   showAllPages: true
	}),
	methods: {
    getData(){
        this.$store.state.componentloader = true
        axios.get('/org-generated-reports').then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.generateddocuments = response.data.details
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },

      download(req,value){
        this.$store.state.componentloader = true
        axios.get(`/org-download-reports/${req.id}/${value}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              if(value == "excel" && response.data.file !== null) {
                const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = `${req.organisation_name}-${req.start_date}_${req.end_date}_Draft_Excel.xlsx`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
              }
              if(value == "pdf" && response.data.file !== null) {
                const linkSource = `data:application/pdf;base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = `Invoice-${req.organisation_name}_${req.start_date}_${req.end_date}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
              }
              if(value == "voucher" && response.data.file !== null) {
                const linkSource = `data:application/zip;base64,${response.data.file}`;
                const downloadLink = document.createElement("a");
                const fileName = `Vouchers-${req.organisation_name}_${req.start_date}_${req.end_date}.zip`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
              }
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
      
	},
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.$store.state.generateddocuments = []
          this.getData()
        }
      }
  },
	props: {
     value: Boolean,
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    alldocuments() {
      return this.$store.state.generateddocuments
    },
    documents() {
      return this.alldocuments
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
	mounted() {
  },
	created() {}
}
</script>