<template>
  <div>
    <!-- repeat modal -->
      <v-dialog
        v-model="show"
        max-width="500px"
        >
          
          <v-card
            class="mx-auto profiled"
            v-if="profiledetails.length > 0"
            >

            <v-form class="pt-0"  enctype="multipart/form-data" v-model="valid" ref="form">

              <v-card-title class="mb-0 pb-0">
                <span class="text-h6">Repeat Booking</span>
              </v-card-title>
              <v-card-text class="mb-0 mt-0 pt-0 mx-0 px-0">
                <v-container fluid>
                  <v-row class="py-2" style="padding-bottom:0 !important">

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >

                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>
                                <div>{{ profiledetails[0].name }}</div>
                              </td>
                              <td>Telephone</td>
                              <td>
                                <div>{{ profiledetails[0].telephone }}</div>
                              </td>
                            </tr>
                            <tr>
                              <td>Trip Type</td>
                              <td>
                                <span v-if="profiledetails[0].trip_type === '1'">One Way</span>
                                <span v-else>Wait and Return</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Pickup Date</td>
                              <td colspan="3">{{ profiledetails[0].date }} {{ profiledetails[0].time }}</td>
                            </tr>
                            <tr>
                              <td>From</td>
                              <td colspan="3">{{ profiledetails[0].from }}</td>
                            </tr>
                            <tr>
                              <td>To</td>
                              <td colspan="3">{{ profiledetails[0].to }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Select pickup date*"
                            v-bind="attrs"
                            v-on="on"
                            :rules="fieldRules"
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        v-model="time"
                        label="Pickup Time*"
                        type="tel" 
                        v-mask="'##:##'"
                        required
                        list="data"
                        autocomplete="off"
                      ></v-text-field>
                      <datalist id="data">
                          <option v-for="time in $store.state.times" :value="time" />
                      </datalist>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions class="pt-2">
                <v-spacer></v-spacer>
                  <v-btn
                    color="default darken-1"
                    text
                    @click="show = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary darken-1"
                    :loading="loading"
                    :disabled="!valid"
                    @click="create"
                  >
                    Submit
                  </v-btn>
              </v-card-actions>

            </v-form>
            
          </v-card>

      </v-dialog>
      <!-- end repeat modal -->
  </div>
</template>

<style>
.newcustomer{
  cursor: pointer;
}
.v-breadcrumbs a {
  color: #F9780D !important;
}
.dtable td{
  padding: .3rem!important;
}
.dtable td:nth-child(2) {
  white-space: normal !important;
}
  .v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
    font-size: 70px !important;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important; 
  }
  .v-input--selection-controls {
     margin-top: 6px !important; 
     padding-top: 4px !important; 
  }

    .vue__time-picker-dropdown, .vue__time-picker .dropdown{
      z-index: 10001 !important;
    }
    .vue__time-picker input.display-time {
      flex: 1 1 auto !important;
      line-height: 20px !important;
      border: 0 !important;
      border-bottom: 1px solid #949494 !important;
      min-width: 100% !important;
      width: 100% !important;
      height: 1.2em !important;
  }
  .vue__time-picker{
    width: 100% !important;
  }
  .vue__time-picker-dropdown, .vue__time-picker .dropdown{
    width: 60%;
  }
  .vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list{
    width:100%;
  }
  input.display-time:focus{
    border-bottom: 1px solid #F97B12 !important;
    box-shadow: none !important;
    outline: none;
  }
  .vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover{
    background:  #F97B12;
  }
</style>
<style scoped>
  .v-label{
    text-transform: capitalize !important;
  }
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
  name: 'RepeatBooking',
  components: {
  },
  data: () => ({
    fieldRules: [
      (v) => !!v || 'Field is required'
    ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    time: "",
    date: "",
    menu: false,
    menu2: false,

  }),
  methods: {
    async create(){

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.profiledetails[0].id)
            fd.append('pickupdate',this.date)
            fd.append('pickuptime',this.time)

            axios.post('/repeat-booking', fd, config).then(response=>{

              this.show = false
              
              if(response.data.status == parseInt(200)) {
                this.$toast.success('Booking addded successfully','Success',{position: 'topRight',timeout: 7000,})
                this.clear()
              }

              this.date = ""
              this.time = ""

              this.loading = false
              this.disabled = false
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }
      },
  },
  props: {
       value: Boolean,
       profiledetails: Array
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
           this.$emit('input', value)
        }
      }
    },
  mounted() {
   
  },
  created() {
     
    }
}
</script>