<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
      </div>
      <div class="pending">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0" style="padding-top:0 !important;">
              <v-card class="card-shadow pb-0 pt-0">
                <v-card-title class="py-0 my-0" style="display:flex;align-items:center;justify-content:flex-end;height:3rem">
                  <v-spacer/>
                  <v-col md="2" cols="12" sm="12" class="px-0" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                      v-model="search"
                      label="Search bookings"
                      class="mx-4"
                      hide-details="auto"
                      clearable
                      @click:clear="onClearClicked"
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-card-text class="px-0 pb-0 cardtext">
                  <div class="empty-state" v-if="allbookings.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                        :items="allbookings"
                        :item-height="23"
                        renderless 
                        id="virtualscroll"
                        class="allbooking"
                       >
                        <template v-slot:default="{ item }">
                            <div class="tcell" :class="item.color">
                              <div class="name">{{ item.name }}<span v-if="item.bookingfor !== null">({{ item.bookingfor }})</span></div>
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.time }}
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.date }}
                            </div>
                            <div class="tcell" :class="item.color">
                              <div>{{ item.telephone }}</div>
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.request_category }}
                            </div>
                        
                            <div class="tcell" :class="item.color">
                              {{ item.from }}
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.to }}
                            </div>
                           
                            <div class="tcell" :class="item.color">
                              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      icon
                                      v-bind="attrs"
                                      small
                                      v-on="on">
                                    <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                  </v-btn>
                                </template>
                                
                                <v-list-item-group
                                  color="primary"
                                  class="actions"
                                  >

                                  <!-- view profile -->
                                    <v-list-item
                                      @click="bookingprofile(item.id)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon color="#000000">mdi-account</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Profile</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                  <!-- edit booking -->
                                  <v-list-item
                                    link
                                    @click="authorize(item.id)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon>mdi-account-lock-open</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Authorize</v-list-item-title>
                                        </v-list-item-content>
                                  </v-list-item>
                                  <!-- Cancel booking -->
                                  <v-list-item
                                    @click="cancelauthorization(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#3f775a">mdi-close</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Decline</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                </v-list-item-group>
                              </v-menu>
                            </div>
                            <div class="tcell" :class="item.color">
                                <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.name }} - {{ res.registration }},</span></div>
                                <div v-else>Pending</div>
                            </div>
                            <div class="tcell" :class="item.color">
                              <span
                              >
                                {{ item.vehicle_type_name }}
                              </span>
                            </div>
                            <div class="tcell" :class="item.color">
                              <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
                              <span
                                v-if="item.authorization == 'false'"
                              >
                                <v-icon>mdi-account-lock</v-icon>
                              </span>
                              <v-menu
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  location="end"
                                  v-if="item.authorization == 'declined'"
                                >
                                  <template v-slot:[`activator`]="{ props }">
                                    <span
                                    >
                                      <v-icon>mdi-cancel</v-icon>
                                    </span>
                                  </template>

                                  <v-card min-width="300">
                                    <v-list>
                                      <v-list-item
                                        prepend-avatar=""
                                        :title="item.name"
                                        :subtitle="item.telephone"
                                      >
                                      </v-list-item>
                                    </v-list>

                                    <v-divider></v-divider>

                                    <p>
                                      {{ item.declined.reason }}
                                    </p>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>

                                      <v-btn
                                        variant="text"
                                        @click="menu1 = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        color="primary"
                                        variant="text"
                                        @click="menu1 = false"
                                      >
                                        Save
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                            </div>
                            <div class="tcell" :class="item.color">
                                <span>{{ item.user_name }}</span>
                            </div>
                            <div class="tcell" :class="item.color">
                              <span
                                v-if="item.remarks !== null"
                              >
                                {{ item.remarks }}
                              </span>
                            </div>
                        </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>

        <!-- assign cancel -->
        <v-dialog
          v-model="updatedialog"
          max-width="450px"
          >
          <v-card v-if="selected">

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Decline Authorization</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                      outlined
                        label="Client"
                        readonly
                        :value="selected.name"
                        prepend-inner-icon="mdi-account"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                      outlined
                        label="Telephone"
                        readonly
                        :value="selected.telephone"
                        prepend-inner-icon="mdi-phone"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                      outlined
                        label="From"
                        readonly
                        :value="selected.from"
                        prepend-inner-icon="mdi-map-marker"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                      outlined
                        label="To"
                        readonly
                        :value="selected.to"
                        prepend-inner-icon="mdi-map-marker"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        outlined
                        name="input-3-4"
                        rows="2"
                        row-height="20"
                        label="Reason"
                        v-model="reason"
                        :rules="fieldRules"
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="sendreject"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- end cancel -->
        <v-dialog
          v-model="progressdialog"
          hide-overlay
          persistent
          width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Please wait
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
        </v-dialog>
      </div>
    </div>
</template>
<style scoped>
  @import "@/assets/css/allbookings_scoped";
</style>
<style>
  @import "@/assets/css/allbookings";
</style>
<script>

import ClientProfile from '@/components/bookings/submodules/today/ClientProfile'

import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"pendingList",
    components: {ClientProfile},
    data(){
        return {
          searchfilter: '',
          message: false,
          hints: true,
          path: null,
          reveal: false,
          profiledialog: false,
          updatedialog: false,
          profiledetails: [],
          search: "",
          labelpickupl: "",
          labeldropl:"",
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          service: null,
          fields: [
            { text: 'Passenger', align: 'start', value: 'name',  },
            { text: 'Time', value: 'time', filterable: false },
            { text: 'Date', value: 'date', filterable: false },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Category', value: 'category' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Action', value: 'action' },
            { text: 'Assigned Vehicle', value: 'assigned' },
            { text: 'Car Option', value: 'vehicletype_type' },
            { text: 'Status', value: 'status' },
            { text: 'Booked By', value: 'bookedby' },
            { text: 'Remarks', value: 'remarks' },
           ],
            isBusy: false,
            loading: false,
           disabled: false,
           height:"100%",
           rowHeight: 25,
            perPage: 100,
            start: 0,
            timeout: null,
            dates: {},
            date: "",
            daterange: {
              start: '',
              end: ''
            },
            menu:false,
            menu1:false,
            selected: null,
            reason: '',
            valid: true,
            progressdialog: false,
            tableloader: false,
            timer: null
          }
    },
    beforeMount() {
      // if(!ability.can('booking-management', 'all')) {
      //   this.$router.push({ name: 'DashboardPage' })
      //   return
      // }
    },
    beforeDestroy() {
    },
    mounted(){

      this.$store.state.loader_text = 'Pending Authorization'

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

      this.getBookings()

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      bookings() {
        const activeprojects = this.$store.state.user.user.projects.map(item => {

            return parseInt(item.project_id)

          })

        return this.$store.state.unauthorizedbookings
        .filter(item => {
          this.start = 0
            if(item.projects.map((val) => { return parseInt(val.id) }).some(item => activeprojects.includes(item)) === true ||
              item.individuals.some(item => this.$store.state.user.user.id = item.status == 'active' ? item.approver_id : 0) === true) {
              return item
            }else {
              return false
            }

        })
        .filter(item => {

          this.start = 0

          let name = "",time = "",date = "",telephone = "",organisation = "",from = "", to = "",vehicletype = "",orderedby = "",status = "";
          if(item.name != null && item.name != 'null') {name = item.name}
          if(item.time != null && item.time != 'null') {time = item.time}
          if(item.date != null && item.date != 'null') {date = item.date}
          if(item.telephone != null && item.telephone != 'null') {telephone = item.telephone}
          if(item.from != null && item.from != 'null') {from = item.from}
          if(item.to != null && item.to != 'null') {to = item.to}
          if(item.vehicletype != null && item.vehicletype != 'null') {vehicletype = item.vehicletype_type}
          if(item.orderedby != null && item.orderedby != 'null') {orderedby = item.orderedby}
          if(item.status != null && item.status != 'null') {status = item.status}

            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || telephone.includes(this.search) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || vehicletype.toLowerCase().includes(this.search.toLowerCase()) || orderedby.toLowerCase().includes(this.search.toLowerCase()) || status.toLowerCase().includes(this.search.toLowerCase())
        })

      },
      allbookings() {
        return this.bookings
      },
    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

    },
    methods:{
      onClearClicked () {
        this.search = ''
      },
      cleardate () {
        this.date = ''
        this.menu = false
      },
      columnValueList(val) {
        return this.bookings.map((d) => d[val]);
      },
      getBookings(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getUnAuthorizedBookings')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
      bookingprofile(id) {

      this.$store.state.bookingid = id

      this.profiledialog = true
    },
    authorize(id) {
      this.progressdialog = true
      axios.get(`/booking/authorize/${id}`).then(response=>{
        this.progressdialog = false
        if(response.data.status == parseInt(200)) {
          let index = this.$store.state.unauthorizedbookings.findIndex(booking => booking.id == response.data.id);
          if (index > -1) {
            this.$store.state.unauthorizedbookings.splice(index, 1);
          }
          this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
        }
        
      })
      .catch(() => {
        this.progressdialog = false
        console.log("Unexpected error occured")
      })
    },
    cancelauthorization(id) {
      let index = this.allbookings.filter(item => item.id === id )
        if(index.length > 0) {

          this.selected = index[0]
          this.updatedialog = true

        }
    },
    sendreject() {
      if (this.$refs.form.validate()) {

        this.loading = true
        this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('reason',this.reason)
          fd.append('id',this.selected.id)

          axios.post('/booking/decline-authorize', fd, config).then(response=>{
            this.loading = false
            this.disabled = false
            if(response.data.status === 200) {
              let index = this.$store.state.unauthorizedbookings.findIndex(booking => booking.id == response.data.id);
              if (index > -1) {
                this.$store.state.unauthorizedbookings.splice(index, 1);
              }
              this.$toast.success('Authorisation was declined','Success',{position: 'topRight',timeout: 7000,})
              this.reason = ''
              this.selected = null
              this.updatedialog = false
            }
          }).catch(error=>{
            this.loading = false
            this.disabled = false
              console.log(error)
          })

        }
    },

  }
}
</script>