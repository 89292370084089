import Vue from 'vue'
import Vuex from 'vuex'
import {ability} from '@/services/ability';
import axios from 'axios'

Vue.use(Vuex)
import moment from "moment";
import { format, parseISO } from 'date-fns'

axios.defaults.baseURL = 'https://backgroundprocess.absolutecabs.co.ke/api/absolute'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/absolute'

const reportdata = {
      project_id: null,
      customer_id: null,
      status: '',
      request_category: '',
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }

export default new Vuex.Store({
  state: {
    times:["00:00","00:30","01:00","01:30","02:00","02:30","03:00","03:30","04:00","04:30","05:00","05:30","06:00","06:30","07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"],
    searchdate: "",
    todaysearchdate: "",
    searchbooking: "",
    todaysearchbooking: "",
    entrances: [],
    exits: [],
    expresswayrates: [],
    freevehicles: [],
    commission_rates: [],
    allbookingreports: [],
    todaybookings: [],
    vehicletypes: [],
    quotations: [],
    quotationdetails: [],
    quotationdetail: null,
    showquotations: [],
    rates: [],
    ratings: [],
    disciplinaries: [],
    generateddocuments: [],
    user: null,
    loader_text: "",

    pendingbookings: [],
    bookingspending: [],
    bookingsinprogress: [],
    unauthorizedbookings: [],
    projects: [],
    generateddocuments: [],
    confirm_title: '',
    confirm_message: '',
    reportdata: reportdata,

    pdfSource: null,
    pdf: "",
    excel: "",
    voucher: "",
    selectedquotation: null,

    // components
    invoiceorganisation: null,
    statementorganisation: null,
    reportorganisation: null,
    linkdebitdata: null,
    linkcreditdata: null,
    linkpaymentdata: null,
    componentloader: false,
    reportcustomers: [],
    reportprojects: [],

    // settings variables
    settingsapikey: "",
    settingspartnerid: "",
    settingsshortcode: "",
    settingsvat: "",
    settingsratecharges: [],
    settingsVehicleType: [],

    bookingorganisations: [],
    bookingcustomers: [],
    bookingprojects: [],
    bookingvehicletype: [],
    bookingvehicles: [],
    bookingdrivers: [],
    editinvoice: null,
    invoicedetail: null,
    paymentdetail: null,
    creditnotedetail: null,
    debitnotedetail: null,
    invoicedetails: [],
    quotationdetails: [],
    showinvoices: [],
    showpayments: [],
    showcreditnotes: [],
    showdebitnotes: [],
    minorganisations: [],
    componentvehicles: [],
    reportorganisations: [],
    reportcustomers: [],
    updateorganisations: [],
    assignvehicles: [],
    assigndrivers: [],
    statements: [],
    newdata: null,

    vat: null,
    bookingid: null,
    tableloader: false,
    tableloader1: false,
    tptableloader: false,
    addbookingloader: false,
    userPermissions: [],
    drivers: [],
    logs: [],
    customers: [],
    invoicebookings: [],
    creditnotes: [],
    debitnotes: [],
    organisations: [],
    invoices: [],
    payments: [],
    commissions: [],
    paymentreversals: [],
    modes: [],
    vehicles: [],
    make:[],
    vehicletype:[],
    model:[],
    ownership:[],
    orgcategory: [],
    bookings: [],
    allbookings: [],
    all_bookings: 0,
    todaycommission: 0,
    totalcommission: 0,
    orgtype: [],
    users: [],
    roles: [],
    disciplinaries: [],
    profiledetails: null,
    in15count:0,
    over15count:0,
    in30count:0,
    in2count:0,
    over2count:0,
    latecount:0,
    bookingcount:0,
    cancelledcount:0,
    allcount:0,
    dayscount: 0,
    searchFilter: '',
    dashboard_stats: [],
    arrayChart: null,
    orgarray: null,
    categories: null,
    orgchartbooking: null,
    distance: 0,
    stop1: 0,
    stop2: 0,
    dist: 0,
    end: 0,
    distanceTime: 0,
    stop1Time: 0,
    stop2Time: 0,
    endTime: 0,

    origin: null,
    destination: null,
    waypoints: [],
    entrances: [],
    exits: [],
    documents: [],
    tableloader: false,

    today: moment().format("YYYY-MM-DD"),
  },
  mutations: {
      clearDate(state) {
      state.searchdate = ""
    },
        sidebarVariant(state, data) {
            state.mini = data
        },
    getVoucher(state, data) {
      if(data.statusCode === parseInt(200)) {

        state.voucher = data.booking
        state.rate = data.rate
      }
    },

    // account
    setUserData (state, userData) {

      if(userData.status == 403){
        return;
      }

      state.userPermissions = userData.userPermissions
      
      let formattedRules = [];
          if (state.userPermissions.length > 0) {
              formattedRules = state.userPermissions.map(perm => {
                  let formattedObj = {};
                  formattedObj.subject = 'all';
                  formattedObj.action = perm.substr(perm.indexOf(' ') + 1);
                  return formattedObj;
              })
          }
      ability.update(formattedRules)
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    },

    // customers
    newCustomer(state, data) {
      let found = state.customers.find(customer => customer.id == data.id);
      if (!found) {
        state.customers.unshift(data)

        return data
      }
    },

    getRates(state, data) {
      state.subprojects = data.subprojects
      state.rates = data.rates
      state.base_amount = data.base_amount

      state.subprojects.forEach((value, index) => {
                  state.sprojects.push({
                    project_id: value.passenger_name,
                    subproject_id: value.telephone
                  })
              });
    },

    // bookings in progress
    getBookingsInProgress(state, data) {

      state.tableloader = false

      if(data.status === parseInt(200)) {

        const formattedBookings = data.bookings.map(item => {
            const container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

          //   let found = state.bookingsinprogress.find(book => book.id == container.id);
          // if (!found) {
          //   state.bookingsinprogress.push(container)
          // }
          return container

        })
        state.bookingsinprogress = formattedBookings
      
      }
    },

    // bookings pending
    getBookingsPending(state, data) {

      state.tableloader = false

      if(data.status === parseInt(200)) {

        const formattedBookings = data.bookings.map(item => {
            const container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user = item.user_name;
            container.user_name = item.user_name;

          //   let found = state.bookingspending.find(book => book.id == container.id);
          // if (!found) {
          //   state.bookingspending.push(container)
          // }
          return container

        })
        state.bookingspending = formattedBookings
      }
    },

    getUnAuthorizedBookings(state, data) {

      if(data.status === parseInt(200)) {

        const formattedBookings = data.bookings.map(item => {
            const container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;
            container.projects = item.bookingprojects;
            container.individuals = item.individualapprover;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.projects = item.bookingprojects;
            container.user_name = item.user_name;

          //   let found = state.unauthorizedbookings.find(book => book.id == container.id);
          // if (!found) {
          //   state.unauthorizedbookings.push(container)
          // }
            return container
        })
        state.unauthorizedbookings = formattedBookings
      }
    },

    // bookings
    getBookings(state, data) {

      state.tableloader = false

      if(data.status === parseInt(200)) {
    
        const formattedBookings = data.bookings.map(item => {
            const container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

            // let found = state.bookings.find(book => book.id == container.id);
            //   if (!found) {
            //     state.bookings.push(container)
            //   }

            return container

        })
        state.bookings = formattedBookings
      }
    },

    //tracking bookings
    getTrackingBookings(state, data) {

      state.tableloader = false

      if(data.status === parseInt(200)) {

        const formattedBookings = data.bookings.map(item => {
            const container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

            // let found = state.trackingbookings.find(book => book.id == container.id);
            //   if (!found) {
            //     state.trackingbookings.push(container)
            //   }
            return container

        })
        state.trackingbookings = formattedBookings

      }
    },
    driverlocation(state, data) {
      if(data) {
        // console.log(data)
        if(data.drivers.length > 0) {
          state.markerlocationupdate = data
          // let currentPosition = new google.maps.LatLng({
        //           lat: parseFloat(data.drivers[0].driver.driver[0].latitude),
        //           lng: parseFloat(data.drivers[0].driver.driver[0].longitude),
        //         });

          // if(state.markers[data.id]) {
          //  state.markers[data.id].setPosition(currentPosition)
          // }
        }
      }

            // console.log(data)

    },
    bookingCreated(state, data) {

      state.tableloader = false

      let found = state.bookingspending.find(booking => booking.id == data.id);
      if (!found) {
        if(data.organisation_id === state.user.user.organisation_id) {
          let item = data
          // new booking
          const container = {};
            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

          state.bookingspending.unshift(container)

          // return container
        }
      }
    },
    bookingEdit(state, data) {

      state.tableloader = false

      let found = state.bookings.findIndex(booking => booking.id == data.id);

      if (found > -1) {

        if(data.organisation_id === state.user.user.organisation_id) {

              let item = data
          // new booking
          const container = {};
            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;
            
               state.bookings.splice(found, 1, container);
           }
          }
    },
    bookingDelete(state, data) {
      let index = state.bookings.findIndex(booking => booking.id == data.id);
      if (index > -1) {
        state.bookings.splice(index, 1);
      }
    },
    getDashboard(state, data) {
            state.dashboard_stats = data.dashboard_stats
    },
    loaddata(state, data) {

      state.tableloader = false

      if(data.status === parseInt(200)) {

        state.bookingcount = data.count

        let formattedBookings = data.allbookings.map(item => {
            let container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

            let found = state.bookings.find(book => book.id == container.id);
              if (!found) {
                state.bookings.push(container)
              }

        });

      }
    },
    loadbookingsbydate(state, data) {

      state.tableloader = false
      state.bookings = []

      if(data.status === parseInt(200)) {

        state.bookingcount = data.count

        let formattedBookings = data.allbookings.map(item => {
            let container = {};

            container.name = item.passenger_name !== null ? item.passenger_name : '';
            container.date = item.pickup_date !== null ? item.pickup_date : '';
            container.time = item.pickup_time !== null ? item.pickup_time : '';
            container.telephone = item.telephone !== null ? item.telephone : '';
            container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
            container.from = item.travelfrom !== null ? item.travelfrom : '';
            container.to = item.travelto !== null ? item.travelto : '';
            container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
            container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

            container.status = item.status;
            container.authorization = item.authorization;
            container.remarks = item.remarks;
            container.trip_type = item.trip_type;
            container.request_category = item.request_category;

            container.request_type = item.request_type;
            container.ref_no = item.ref_no;
            container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
            container.id = item.id;
            container.vehicles = item.bookingvehicles;
            container.user_name = item.user_name;

            state.bookings.push(container)

        });

      }
    },
    getAddBookings(state,data) {
      state.addbookingloader = false
      state.bookingprojects = data.projects
      state.bookingcustomers = data.customers
      state.bookingvehicletype = data.vehicletypes
      state.rates = data.rates
    },

    getOrgCustomer(state,data) {
      state.tableloader = false
      state.reportprojects = data.projects
      state.reportcustomers = data.customers
    },


    loadmoreinprogress(state, data) {

      if(data.status === parseInt(200)) {

        if(data.allbookings.length > 0) {
          let formattedBookings = data.allbookings.map(item => {
              let container = {};

              container.name = item.passenger_name !== null ? item.passenger_name : '';
              container.date = item.pickup_date !== null ? item.pickup_date : '';
              container.time = item.pickup_time !== null ? item.pickup_time : '';
              container.telephone = item.telephone !== null ? item.telephone : '';
              container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
              container.from = item.travelfrom !== null ? item.travelfrom : '';
              container.to = item.travelto !== null ? item.travelto : '';
              container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
              container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

              container.status = item.status;
              container.authorization = item.authorization;
              container.remarks = item.remarks;
              container.trip_type = item.trip_type;
              container.request_category = item.request_category;

              container.request_type = item.request_type;
              container.ref_no = item.ref_no;
              container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
              container.id = item.id;
              container.vehicles = item.bookingvehicles;
              container.user_name = item.user_name;

              // state.bookingsinprogress.push(container)
              let found = state.bookingsinprogress.find(book => book.id == container.id);
                if (!found) {
                  state.bookingsinprogress.unshift(container)
                }else {
                  state.bookingsinprogress.splice(found, 1, container);
                }

          });
        }

      }
    },
    loadmorebookingpending(state, data) {

      if(data.status === parseInt(200)) {

        if(data.allbookings.length > 0) {
          let formattedBookings = data.allbookings.map(item => {
              let container = {};

              container.name = item.passenger_name !== null ? item.passenger_name : '';
              container.date = item.pickup_date !== null ? item.pickup_date : '';
              container.time = item.pickup_time !== null ? item.pickup_time : '';
              container.telephone = item.telephone !== null ? item.telephone : '';
              container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
              container.from = item.travelfrom !== null ? item.travelfrom : '';
              container.to = item.travelto !== null ? item.travelto : '';
              container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
              container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

              container.status = item.status;
              container.authorization = item.authorization;
              container.remarks = item.remarks;
              container.trip_type = item.trip_type;
              container.request_category = item.request_category;

              container.request_type = item.request_type;
              container.ref_no = item.ref_no;
              container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
              container.id = item.id;
              container.vehicles = item.bookingvehicles;
              container.user_name = item.user_name;

              // state.bookingspending.push(container)
              let found = state.bookingspending.find(book => book.id == container.id);
                if (!found) {
                  state.bookingspending.unshift(container)
                }else {
                  state.bookingspending.splice(found, 1, container);
                }

          });
        }

      }
    },
    loadmorepending(state, data) {

      if(data.status === parseInt(200)) {

        if(data.allbookings.length > 0) {
          let formattedBookings = data.allbookings.map(item => {
              let container = {};

              container.name = item.passenger_name !== null ? item.passenger_name : '';
              container.date = item.pickup_date !== null ? item.pickup_date : '';
              container.time = item.pickup_time !== null ? item.pickup_time : '';
              container.telephone = item.telephone !== null ? item.telephone : '';
              container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
              container.from = item.travelfrom !== null ? item.travelfrom : '';
              container.to = item.travelto !== null ? item.travelto : '';
              container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';
              container.vehicle_type_name = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

              container.status = item.status;
              container.authorization = item.authorization;
              container.remarks = item.remarks;
              container.trip_type = item.trip_type;
              container.request_category = item.request_category;

              container.request_type = item.request_type;
              container.ref_no = item.ref_no;
              container.bookingfor = item.bookingfor !== null && item.bookingfor !== 'null' && item.bookingfor !== '' ? item.bookingfor.split(",")[0] : null;
              container.id = item.id;
              container.vehicles = item.bookingvehicles;
              container.user_name = item.user_name;

              // state.unauthorizedbookings.push(container)
              let found = state.unauthorizedbookings.find(book => book.id == container.id);
                if (!found) {
                  state.unauthorizedbookings.unshift(container)
                }else {
                  state.unauthorizedbookings.splice(found, 1, container);
                }

          });
        }

      }
    },
  },
  actions: {

    getOrgCustomer ({ commit,state }) {
      return axios
        .get('/orgreportorganisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
        .then(({ data }) => {
          commit('getOrgCustomer', data)
        })
    },

    getAddBookings ({ commit,state }) {
      return axios
        .get('/orgbookingorganisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
        .then(({ data }) => {
          commit('getAddBookings', data)
        })
    },

  loadmoreinprogress ({ commit,state },res) {
    return axios
    .get(`/org/loadmoreinprogress/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('loadmoreinprogress', data)
    })
  },
  loadmorebookingpending ({ commit,state },res) {
    return axios
    .get(`/org/loadmorebookingpending/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('loadmorebookingpending', data)
    })
  },
  loadmorepending ({ commit,state },res) {
    return axios
    .get(`/org/loadmorepending/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('loadmorepending', data)
    })
  },

    loadBookingData ({ commit,state },res) {
    return axios
    .get(`/org/loaddata/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('loaddata', data)
    })
  },
  loadbookingsbydate ({ commit,state },res) {
      return axios
      .get(`/org/loadbookingsbydate/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
      .then(({ data }) => {
        commit('loadbookingsbydate', data)
      })
    },
    pushdata ({ commit }) {
        commit('pushdata')
    },
    sidebarVariant ({ commit }, data) {
        commit('sidebarVariant', data)
    },
    getVoucher ({ commit },res) {
    return axios
    .get(`/customer/e-voucher/${res.refno}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getVoucher', data)
    })
  },

  getDashboard ({ commit, state }) {
    return axios
    .get(`/dashboard/org-stats/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {

      commit('getDashboard', data)
    })
  },
  getUnAuthorizedBookings ({ commit, state }) {
    return axios
    .get(`/org/unauthorizedbookings/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {

      commit('getUnAuthorizedBookings', data)
    })
  },
  // bookings
  getRates ({ commit, state }) {
    return axios
    .get('/rates',{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getRates', data)
    })
  },
  getBookings ({ commit, state }) {
    return axios
    .get(`/org/bookings/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getBookings', data)
    })
  },
  getTrackingBookings ({ commit, state }) {
    return axios
    .get(`/org/trackingbookings/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getTrackingBookings', data)
    })
  },
  getBookingsPending ({ commit, state }) {
    return axios
    .get(`/org/bookings-pending/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getBookingsPending', data)
    })
  },
  getBookingsInProgress ({ commit, state }) {
    return axios
    .get(`/org/bookings-in-progress/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('getBookingsInProgress', data)
    })
  },
  bookingCreated ({ commit }, res) {
    return axios
    .get(`/pull-newbooking/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('bookingCreated', data)
    })
    // commit('bookingCreated', data)
  },
  bookingEdit ({ commit }, res) {
    return axios
    .get(`/pull-bookingedit/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('bookingEdit', data)
    })
    // commit('bookingEdit', data)
  },
  driverlocation ({ commit, state }, res) {
    return axios
    .get(`/fetch-driverlocation/${res}/${state.user.user.organisation_id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('driverlocation', data)
    })
    // commit('bookingEdit', data)
  },
  bookingDelete ({ commit }, data) {
    commit('bookingDelete', data)
  },
  // account
  login ({ commit }, credentials) {
    return axios
    .post('/org-login', credentials,{ headers: { "Access-Control-Allow-Origin": "*" } })
    .then(({ data }) => {
      commit('setUserData', data)
    })
  },

  logout ({ commit, state }) {
    commit('clearUserData')
  }
  },
  getters : {
    isLogged: state => !!state.user,
    markerlocationupdate (state) {
        return state.markerlocationupdate
      }
  },
  modules: {},
})
