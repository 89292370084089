import Vue from 'vue'
import VueRouter from 'vue-router'

import DashboardPage from '@/views/Dashboard.vue'
// bookings
import pendingList from '@/views/bookings/pending.vue'

import bookingList from '@/views/bookings/index.vue'

import bookingProgress from '@/views/bookings/inprogress.vue'
import bookingPending from '@/views/bookings/bookingpending.vue'

import bookingEdit from '@/views/bookings/edit.vue'
import addBookingList from '@/views/bookings/newbooking.vue'
import returnBooking from '@/views/bookings/returnbooking.vue'
// bookings
import ReportsPage from '@/views/reports/index.vue'
import InvoiceReport from '@/views/reports/invoice.vue'
// config
import usersList from '@/views/config/users.vue'

import LoginPage from '@/views/LoginPage.vue'
// const Signup = () => import('../views/Signup.vue')

import ForgotPassword from '@/views/forgotpassword.vue'
import ResetPasswordForm from '@/views/resetpassword.vue'
// settings
import budgetCodes from '@/views/org/budgetcodes/index.vue'

Vue.use(VueRouter)

const routes = [
 // {
  //   path: "/customer/e-voucher/:id",
  //   name: "home",
  //   component: HomeView,
  // },
  { 
    path: '/forgot/password', 
    name: 'ForgotPassword', 
    component: ForgotPassword, 
    meta: {
      auth: undefined
    }
  },
  { 
    path: '/reset/:token', 
    name: 'ResetPasswordForm', 
    component: ResetPasswordForm, 
    meta: {
      auth: undefined
    } 
  },
  {
    path: '/new/return/booking',
    name: 'returnBooking',
    component: returnBooking,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  {
    path: '/new/booking',
    name: 'addBookingList',
    component: addBookingList,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  // {
  //     name: 'orgProjectList',
  //     path: '/organisation/projects/list',
  //     component: () => import('../components/org/projects/index.vue'),
  //     meta: {
  //       auth: true
  //     }
  // },
  {
      name: 'budgetCodes',
      path: '/organisation/projects/list',
      component: budgetCodes,
      meta: {
        auth: true,
        sidebar: false,
      }
  },
  {
    path: '/booking/history',
    name: 'bookingList',
    component: bookingList,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  {
    path: '/booking/pending',
    name: 'bookingPending',
    component: bookingPending,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  {
    path: '/booking/in-progress',
    name: 'bookingProgress',
    component: bookingProgress,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  {
    path: '/booking/pending-requests',
    name: 'pendingList',
    component: pendingList,
    meta: {
      auth: true,
      sidebar: false,
    }
  },
  {
      name: 'bookingEdit',
      path: '/booking/:id/edit',
      component: bookingEdit,
      meta: {
        auth: true,
        sidebar: false,
      }
  },
  {
    path: '/booking/reports',
    name: 'ReportsPage',
    component: ReportsPage,
    meta: {
      auth: true,
      sidebar: false,
    }
    // beforeEnter: (to, from, next) => {
    //     if(ability.can('view-reports', 'all')) {
    //       next()
    //       return
    //     }else {
    //       next(false)
    //       return
    //     } 
        
    //   }
  },
  {
    path: '/booking/invoice',
    name: 'InvoiceReport',
    component: InvoiceReport,
    meta: {
      auth: true,
      sidebar: false,
    }
    // beforeEnter: (to, from, next) => {
    //     if(ability.can('view-reports', 'all')) {
    //       next()
    //       return
    //     }else {
    //       next(false)
    //       return
    //     } 
        
    //   }
  },
  {
    path: '/',
    name: 'DashboardPage',
    component: DashboardPage,
    meta: {
      auth: true,
      sidebar: false
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      auth: undefined,
    }
  },
  // {
  //   path: '/auth/register',
  //   name: 'Signup',
  //   component: Signup
  // },
  
  // users
  {
      name: 'usersList',
      path: '/users/list',
      component: usersList,
      meta: {
        auth: true,
        sidebar: false,
      }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }else {
    next()
  }
})

export default router
