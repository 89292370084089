<template>
    <!-- BEGIN: Content-->
    <div class="budgetcodes mt-3">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click="editOrg(0)"
          >
            {{ $store.state.user.user.organisation.projects_slug }}
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="budgetcodes">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0">
              <v-card class="card-shadow pt-0 pb-0">
                <v-card-title class="py-0 my-0" style="display:flex;align-items:center;justify-content:flex-end;height:3rem">
                  <v-col md="12" sm="12" class="d-flex my-0 py-0 px-0 mx-0" style="justify-content:space-between">
                    
                    <v-spacer/>

                    <div style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between;align-items:center">

                      <v-text-field
                          v-model="searchfilter"
                          label="Search"
                          class="mx-0"
                          solo
                          hide-details="auto"
                        ></v-text-field>
                    </div>

                  </v-col>
                </v-card-title>
                <v-card-text class="px-0 pt-0 mt-0 pb-0">
                    <div class="empty-state" v-if="allprojects.length === 0 && !loader">
                      <div class="empty-state__content">
                        <div class="empty-state__icon">
                          <v-img src="@/assets/icon.png" alt=""></v-img>
                        </div>
                        <div class="empty-state__message">No records available.</div>
                      </div>
                    </div>
                    <v-data-table
                      dense
                      :headers="fields"
                      :items="allprojects"
                      :item-class="getRowClass"
                      class="elevation-0 bcodes"

                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      show-expand
                      item-key="name"

                      disable-pagination
                      hide-default-footer
                      id="virtual-scroll-table"
                      v-scroll:#virtual-scroll-table="onScroll"
                      fixed-header
                      >

                      <template
                        v-if="start > 0"
                        v-slot:[`body.prepend`]
                        >
                        <tr>
                          <td
                            :colspan="fields.length"
                            :style="'padding-top:'+startHeight+'px !important'"
                          >
                          </td>
                        </tr>
                      </template>
                      <template
                        v-if="start + perPage < this.projects.length"
                        v-slot:[`body.append`]
                        >
                        <tr>
                          <td
                            :colspan="fields.length"
                            :style="'padding-top:'+endHeight+'px !important'"
                          >
                          </td>
                        </tr>
                      </template>

                      <template v-slot:[`item.unit`]="{ item }">
                        {{ item.unit }}
                      </template>
                      <template v-slot:[`item.description`]="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:[`item.reviewer="{ item }">
                        <v-chip label size="x-small" class="ma-2" v-if="item.projectsapprovers.length > 0" v-for="(approver,index) in item.projectsapprovers" :key="index">{{ approver.name }}</v-chip>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">

                        <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                                <v-list-item
                                  @click="editOrg(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <v-list-item
                                link
                                @click="deleteItem(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>

                      </template>

                      <template v-slot:[`expanded-item`]="{ item }">   
                        <td>
                          <p v-for="(sub,index) in item.subprojects" :key="index">
                            <span v-if="sub.unit == null || sub.unit == 'null' || sub.unit == ''">-</span>
                            <span v-else>{{ sub.unit }}</span>
                          </p>
                        </td>
                        <td>
                          <p v-for="(sub,index) in item.subprojects" :key="index">{{ sub.name }}</p>
                        </td>
                        <td>
                          <p v-for="(sub,index) in item.subprojects" :key="index" style="width:100%;display:flex;align-items:center;">
                            <v-icon
                                :id="sub.id"
                                small
                                class="mr-3"
                                @click="editSub(item.id,sub.id)"
                              >
                                mdi-pencil-outline
                              </v-icon>
                              <v-icon
                                :id="sub.id"
                                small
                                @click="deleteSubItem(sub.id)"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                          </p>
                        </td>
                        <td>
                          <v-btn
                            color="#000000"
                            class="ma-1 black--text"
                            depressed
                            text
                            @click="editSub(item.id,0)"
                          >
                            <v-icon
                              dark
                            >
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </td>
                      </template>

                    </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="450px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6" v-if="item.updateID !== ''">Update {{ $store.state.user.user.organisation.projects_slug }}</span>
              <span class="text-h6" v-else>Add {{ $store.state.user.user.organisation.projects_slug }}</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="mb-0 pb-0"
                    >
                      <v-text-field
                        label="Code"
                        v-model="item.updateunit"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Name*"
                        v-model="item.updatename"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.updateID == ''">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pt-3"
                      >
                        <span style="font-size:14px;font-weight:600">Add sub categories</span>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-for="(subcode, index) in subcodes" 
                        :key="index"
                        style="display:flex;align-items:center;justify-content:space-between"
                        class="pt-0 mt-0"
                        >
                            <v-text-field
                              label="Code"
                              v-model="subcode.code" 
                              :name="`subcodes[${index}][code]`"
                            ></v-text-field>

                            <v-text-field
                              label="Name"
                              v-model="subcode.name" 
                              :name="`subcodes[${index}][name]`"
                            ></v-text-field>

                          <div class="form-group col-md-2 dynamicactions" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addSubcode" class="mx-3">mdi-plus</v-icon>
                            <v-icon @click="removeSubcode(index)">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="update"
                  v-if="item.updateID !== ''"
                >
                  Save
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                  v-else
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
        <!-- sub modal-->
        <v-dialog
          v-model="subdialog"
          max-width="450px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Add Item</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-for="(subcode, index) in subcodes" 
                        :key="index"
                        style="display:flex;align-items:center;justify-content:space-between"
                        class="py-0 my-0 px-0 mx-0"
                        >
                            <v-text-field
                              label="Code"
                              v-model="subcode.code" 
                              :name="`subcodes[${index}][code]`"
                            ></v-text-field>

                            <v-text-field
                              label="Name"
                              v-model="subcode.name" 
                              :name="`subcodes[${index}][name]`"
                            ></v-text-field>

                          <div class="form-group col-md-2 dynamicactions" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addSubcode" class="mx-3">mdi-plus</v-icon>
                            <v-icon @click="removeSubcode(index)">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="subdialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="createsub"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- sub dialog -->
        <!-- update sub modal-->
        <v-dialog
          v-model="subupdatedialog"
          max-width="450px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Update Item</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="mb-0 pb-0"
                    >
                      <v-text-field
                        label="Code"
                        v-model="item.updatesubunit"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Name*"
                        v-model="item.updatesubname"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="subupdatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="updatesub"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update sub dialog -->
      </div>
    </div>

</template>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content td{
  color:var(--black-shade-color) !important;
}
.v-data-table__expanded.v-data-table__expanded__content td p:first-child{
  padding-top: 1rem !important;
}
.v-data-table__expanded.v-data-table__expanded__content td .v-btn{
  width: 100% !important;
  color: var(--blue-color) !important;
}
.v-data-table__expanded.v-data-table__expanded__content td p .v-icon.v-icon{
  color: var(--blue-color) !important;
}
.v-data-table /deep/ .sticky-header {
    position: sticky !important;
    top: var(--toolbarHeight) !important;
  }

  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset !important;
  }

 #virtual-scroll-table {
    /*height: 78vh;*/
    height: calc(100vh - 185px);
    max-height: 100%;
    overflow: auto;
  }
::v-deep .v-data-table__empty-wrapper {
      display: none !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'

export default {
    name:"budgetCodes",
    components: {},
    data(){
        return {
          tableloader: false,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          searchfilter:'',
          singleExpand: true,
          expanded: [],
          search:null,
          valid: true,
          dialog: false,
          updatedialog: false,
          subdialog: false,
          subupdatedialog: false,
          subcodes: [
            {
              code: "",
              name: ""
            }
          ],
          fields: [
            { text: 'Code', value: 'unit' },
            { text: 'Name', value: 'name' },
            { text: 'Reviewer/Approver', value: 'reviewer' },
            { text: 'Action', value: 'action' },
            { text: '', value: 'data-table-expand' },
           ],
          item:{
              updateorganisation_id:"",
              updateunit:"",
              updatename:"",
              updateID: "",

              updatesubunit:"",
              updatesubname:"",
              projectId: "",
              updatesubID: "",

              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%',
            rowHeight: 25,
            perPage: 150,
            start: 0,
            timeout: null 
        }
    },
    beforeMount() {
      // if(!ability.can('organisation-management', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    mounted(){

      this.$store.state.loader_text = `${this.$store.state.user.user.organisation.projects_slug}`

      if(!this.allprojects || this.allprojects.length == 0) {
        this.getProjects()
      }

    },
    serverPrefetch () {
      return this.getProjects()
    },
    computed: {
      projects() {
        return this.$store.state.projects.filter(item => {

          this.start = 0

          let unit = "";
          let name = "";
          if(item.unit !== null) {
            unit = item.unit
          }
          if(item.name !== null) {
            name = item.name
          }
          return unit.toLowerCase().includes(this.searchfilter.toLowerCase()) || name.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      allprojects() {
        return this.projects.slice(this.start, this.perPage+this.start)
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.projects.length - this.start);
      },
      loader() {
        return this.$store.state.tableloader
      },
    },
    methods:{
      addSubcode () {
        this.subcodes.push({
          code: '',
          name: ''
        })
      },
      removeSubcode (index) {
        this.subcodes.splice(index, 1);
      },
      getRowClass(item) {
        return 'row-color' 
      },
      getProjects(){
        this.$store.state.tableloader = true
        axios.get('/org/projects').then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.projects = response.data.projects
        }).catch(error=>{
            console.log(error)
            this.$store.state.tableloader = false
        })
      },
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.projects.length ?
            this.projects.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.updatename)
            fd.append('unit',this.item.updateunit)
            fd.append('subprojects',JSON.stringify(this.subcodes))

            axios.post('/org/projects', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {
                
                let found = this.$store.state.projects.find(data => data.id == response.data.project.id);
                if (!found) {
                  this.$store.state.projects.unshift(response.data.project)
                }

                this.$toast.success('Item added successfully','Success',{position: 'topRight',timeout: 7000,})
                this.item.updatename = ''
                this.item.updateunit = ''
                this.subcodes = []
                this.addSubcode()

              }
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

      },
  
    update(){

      if (this.$refs.form.validate()) {

      this.loading = true
      this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
         fd.append('unit',this.item.updateunit)
         fd.append('name',this.item.updatename)
        fd.append('_method',this.item._method)

        axios.post(`/org/projects/${this.item.updateID}`, fd, config).then(response=>{
          this.loading = false
          this.disabled = false
          if(response.data.status === 200) {
              
              let index = this.$store.state.projects.findIndex(data => data.id == response.data.project.id);
              if (index > -1) {
                this.$store.state.projects.splice(index, 1, response.data.project);
              }
              this.$toast.success('Item updated successfully','Success',{position: 'topRight',timeout: 7000,})
              this.updatedialog = false
              this.item.updatename = ''
              this.item.updateunit = ''
              this.item.updateID = ''
          }
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      }

    },

    createsub(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('subprojects',JSON.stringify(this.subcodes))
            fd.append('id',this.item.projectId)

            axios.post('/org/subprojects', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {
                
                let found = this.$store.state.projects.find(data => data.id == response.data.project.id);
                if (!found) {
                  this.$store.state.projects.unshift(response.data.project)
                }

                this.$toast.success('Item added successfully','Success',{position: 'topRight',timeout: 7000,})
                this.subdialog = false
                this.item.projectId = ''
                this.subcodes = []
                this.addSubcode()
                this.getProjects()

              }
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

      },
  
    updatesub(){

      if (this.$refs.form.validate()) {

      this.loading = true
      this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('_method',this.item._method)
        fd.append('id',this.item.projectId)
        fd.append('name',this.item.updatesubname)
        fd.append('unit',this.item.updatesubunit)

        axios.post(`/org/subprojects/${this.item.updatesubID}`, fd, config).then(response=>{
          this.loading = false
          this.disabled = false
          if(response.data.status === 200) {
              
              let index = this.$store.state.projects.findIndex(data => data.id == response.data.project.id);
              if (index > -1) {
                this.$store.state.projects.splice(index, 1, response.data.project);
              }
              this.$toast.success('Item updated successfully','Success',{position: 'topRight',timeout: 7000,})
              this.subupdatedialog = false
              this.item.updatesubname = ''
              this.item.updatesubunit = ''
              this.item.projectId = ''
              this.item.updatesubID = ''
          }
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      }

    },

    editOrg(id) {
      let index = this.projects.filter(item => item.id === id )
      if(index.length > 0) {

        this.item.updatename = index[0].name
        this.item.updateunit = index[0].unit
        this.item.updateID = index[0].id
        this.updatedialog = true

      }else {
        this.item.updatename = ''
        this.item.updateunit = ''
        this.item.updateID = ''
        this.updatedialog = true
      }
      
    },
    deleteItem(id){
          if(confirm("Are you sure to delete item ?")){
              axios.delete(`/projects/${id}`).then(response=>{
                if(response.data.status === 200) {
                  let index = this.$store.state.projects.findIndex(data => data.id == response.data.project.id);
                  if (index > -1) {
                    this.$store.state.projects.splice(index, 1);
                  }
                  this.$toast.success('Project deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      },
    editSub(id,subId) {
      let index = this.projects.filter(item => item.id === id )
      if(index.length > 0) {
        let subindex = index[0].subprojects.filter(item => item.id === subId )
        if(subindex.length > 0) {

          this.item.projectId = index[0].id
          this.item.updatesubname = subindex[0].name
          this.item.updatesubunit = subindex[0].unit
          this.item.updatesubID = subindex[0].id
          this.subupdatedialog = true

        }else {
          this.item.projectId = index[0].id
          this.item.updatesubID = ''
          this.subdialog = true
        }

      }
      
    },
    deleteSubItem(id){
          if(confirm("Are you sure to delete item ?")){
              axios.delete(`/subprojects/${id}`).then(response=>{
                if(response.data.status === 200) {
                  let index = this.$store.state.projects.findIndex(data => data.id == response.data.project.id);
                  if (index > -1) {
                    this.$store.state.projects.splice(index, 1, response.data.project);
                  }
                  this.$toast.success('Item removed successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.getProjects()
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
    }
}
</script>