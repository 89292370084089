<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">

          <v-btn
              color="#FA811E"
              class="ma-1 white--text"
              depressed
              :loading="generateloader"
              @click="generatedocuments"
            >
              <v-icon
                left
                dark
              >
                mdi-file-document-outline
              </v-icon>
              Generate Documents
            </v-btn>
            <v-btn
              color="#f5f5f5"
              class="ma-1"
              depressed
              @click="viewdocuments"
            >
              <v-icon
                left
                dark
              >
                mdi-eye-outline
              </v-icon>
               View Documents
            </v-btn>

          <!--   <v-btn
              color="#ffffff"
              class="ma-1 black--text"
              depressed
              :loading="loadingexcel"
              @click="exportexcel"
            >
              Export Excel
              <v-icon
                right
                dark
              >
                mdi-file-excel-box
              </v-icon>
            </v-btn> -->
        </div>
      </div>
      <div class="Reports">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row>
              <v-col cols="12" class="pb-0 mb-0">
                <div class="summaryAlert px-4">
               <!--    <div class="mb-2">
                    <span class="summaryTitle">Payment Summary</span>
                  </div> -->
                  <div class="summaryTotals mt-2">
                    <div class="totals">
                      <span class="font-normal">Sub Total</span>
                      <span class="font-medium">KES{{

                              (this.calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0)) | currency('')

                            }}</span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Vat</span>
                      <span class="font-medium">KES{{

                              ((((this.calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisation.vat !== '0') {
                                        if(item.organisationrate.length > 0) {
                                          if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                            amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                          }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                            amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                          }else {
                                            amt = parseInt(item.organisationrate[0].base_rate)
                                          }
                                        }
                                      }
                                    }else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                            }}</span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Total</span>
                      <span class="font-medium">KES{{

                              (this.calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0;

                                if(item.expressway.length > 0) {
                                  amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                                }
                                
                                return acc += amt; 

                              }, 0) + ((((this.calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisation.vat !== '0') {
                                        if(item.organisationrate.length > 0) {
                                          if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                            amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                          }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                            amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                          }else {
                                            amt = parseInt(item.organisationrate[0].base_rate)
                                          }
                                        }
                                      }
                                    } else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                              {  

                                let amt = 0, waitingfee = 0, subamt = 0;
                                if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                  if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                      waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                  }
                                }

                                if(item.waiting.length > 0) {
                                  subamt = item.waiting.reduce((acc1, value) => { 
                                            let total = 0;
                                            if(value.starttime !== null && value.endtime !== null) {
                                              
                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                              }

                                            }

                                            return  acc1 += total
                                          }, 0)
                                }

                                amt = subamt + waitingfee;
                                
                                return acc += amt; 

                              }, 0))*(vat != null ? vat.rate : 0))/100)) + this.calcbookingreports.reduce( (acc, item) => 
                                {  
                                    let amt = 0;
                                    if(parseInt(item.movement_id) == 0) {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }else {
                                      amt = Math.round(item.amount)
                                    }
                                  return acc += amt; 
                                }, 0)) | currency('')

                            }}</span>
                    </div>
                  </div>
                </div>
              </v-col>
          </v-row>
          <v-row style="position:relative !important;z-index:1 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12">
              <v-card class="card-shadow py-0">
                <v-card-title style="display:block;">
                  <v-row class="px-1 pt-1">
                    
                      <Search/>

                  </v-row>
                </v-card-title>
                <v-card-text class="px-0 py-0 cardtext3">
                  <v-row class="mb-0 mt-0">
                    <v-col cols="12" class="mx-3 mb-3 py-0 d-flex align-center" style="height:30px;justify-content:space-between">
                      <v-spacer></v-spacer>
                      <div style="width:250px !important">
                        <v-text-field
                              v-model="search"
                              label="Search"
                              hide-details="auto"
                              class="mx-4"
                            ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-virtual-scroll
                      :items="allbookingreports"
                      :item-height="23"
                      renderless 
                      id="virtualscroll"
                      class="invoice"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    {{ item.passenger_name }}
                                  </span>
                              </template>
                              <span>
                                  <span>
                                    {{ item.passenger_name }}
                                  </span>
                              </span>
                            </v-tooltip>
                          </div>
                          <div class="tcell">
                            <div>{{ item.telephone }}</div>
                          </div>
                          <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                          <div class="tcell">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs"
                                  v-on="on">
                                  {{ item.travelfrom }}
                                </span>
                              </template>
                              <span>
                                {{ item.travelfrom }}
                              </span>
                            </v-tooltip>
                          </div>
                          <div class="tcell">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs"
                                  v-on="on">
                                  {{ item.travelto }}
                                </span>
                              </template>
                              <span>
                                {{ item.travelto }}
                              </span>
                            </v-tooltip>
                          </div>
                        <!--   <div class="tcell">
                            <span v-if="item.vehicletype !== null">{{ item.vehicletype.type }}</span>
                            <span v-else>N/A</span>
                          </div> -->
                          <div class="tcell">{{ item.distance | currency('') }}</div>
                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ item.amount | currency('') }}</div>
                          </div>
                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100) | currency('') }}</div>
                          </div>
                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ (Math.round(item.amount) + ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100)) | currency('') }}</div>
                          </div>
                          <div class="tcell"><a :href="`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${item.ref_no}`" target="_blank">View voucher</a></div>
                          <div class="tcell">
                            <span v-if="item.user_name !== null">
                              {{ item.user_name }}
                            </span>
                            <span v-else>N/A</span>
                          </div>
                      </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- components -->
      <Confirm v-model="confirmdialog"/>
      <GenerateReport v-model="generatereport"/>
    </div>
</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }

    .invoice .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(5%, 9.091%) !important;*/
       grid-template-columns: 13.091% 9.091% 9.091% 15.091% 15.091% 5.091% 5.091% 5.091% 5.091% 9.091% 9.091%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import $ from 'jquery';
import Search from '@/components/reports/search'
import Confirm from '@/components/reports/confirm'
import GenerateReport from '@/components/reports/generatereport'
import { format, parseISO } from 'date-fns'

export default {
    name:"InvoiceReport",
    components: { DateRangePicker,Search,Confirm,GenerateReport },
    data(){
        return {
          confirmdialog: false,
          generatereport: false,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],

          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",
          loadingexcel: false,
          loadingpdf: false,
          generateloader: false,
          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           searchfilter: "",
           searchrequestcategory: "",
           search: "",
           Drivers: [],
           item: {
            request_category: '',
            project_id: '',
            customer_id: ''
           },
           fields: [
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            // { text: 'Vehicle Type', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
            { text: 'E-voucher', value: 'evoucher' },
            { text: 'Bookedby', value: 'bookedby' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            searchorganisation: null,
            searchproject: null,
            searchcustomer: null,
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null 
 
        }
    },
    beforeMount() {
      this.$store.state.allbookingreports = []
      // if(!ability.can('view-reports', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    serverPrefetch () {},
    mounted(){

      this.$store.state.loader_text = "Invoice Report"

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

      if(this.customers.length == 0 || this.projects.length == 0){
        this.getOrgCustomer()
      }

      this.$store.state.reportdata = {
            project_id: null,
            customer_id: null,
            status: '',
            request_category: '',
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          }

    },
    computed: {
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      vat() {
        return this.$store.state.vat
      },
      bookingreports() {
        return this.$store.state.allbookingreports
      },
      allbookingreports() {
        return this.bookingreports.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
        })
      },
      calcbookingreports() {
        return this.allbookingreports
      },
      projects() {
        return this.$store.state.reportprojects
      },
      customers() {
        return this.$store.state.reportcustomers
      },
      organisation_id() {
        return this.$store.state.user.user.organisation.id
      },
      loader() {
        return this.$store.state.tableloader
      },
      reportdata () {
        return this.$store.state.reportdata
      },
      ...mapState({
        searchFilter: 'searchFilter'
      }),
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',this.reportdata.project_id)
          fd.append('customer_id',this.reportdata.customer_id)
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/export/org/excel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.$store.state.user.user.organisation.name}_report_${this.reportdata.start}_to_${this.reportdata.end}.xlsx`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',this.reportdata.project_id)
          fd.append('customer_id',this.reportdata.customer_id)
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/export/org/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'booking_report.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      generatedocuments(){
        this.generateloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }};
          let fd = new FormData();
          fd.append('id',this.organisation_id)
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',this.reportdata.project_id)
          fd.append('customer_id',this.reportdata.customer_id)
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/export/org/excel', fd, config).then(response=>{
         this.generateloader = false
         if(response.data.status === parseInt(200)) {
   
            this.$store.state.confirm_title = "Report Request";
            this.$store.state.confirm_message = "Your report will be generated in a short while. To download the report once generated click on the <b>View Documents</b> button.";
            this.confirmdialog = true

         }
          
        })
        .catch(() => {
          this.generateloader = false
          console.log("Unexpected error occured")
        })
      },
      viewdocuments() {
        this.generatereport = true
      },
      getOrgCustomer(){

        this.$store.state.tableloader = true

        this.$store
          .dispatch('getOrgCustomer')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },

    }
}
</script>